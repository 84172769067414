var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "stage-viewer" } }, [
    _c(
      "div",
      { staticClass: "stage" },
      [
        _vm.showBackButton
          ? _c("heading", { attrs: { company: _vm.currentCompany } })
          : _vm._e(),
        _vm.stage.title
          ? _c("h3", { staticClass: "font-weight-bold mt-5" }, [
              _vm._v(" " + _vm._s(_vm.stage.title) + " "),
            ])
          : _vm._e(),
        _vm.steps?.length > 1
          ? _c("progress-bar", {
              staticClass: "mt-4",
              attrs: { steps: _vm.steps, "step-progress": _vm.slide.progress },
            })
          : _vm._e(),
        _c(
          "div",
          { ref: "slide", staticClass: "slide" },
          [
            _vm.loaded
              ? [
                  _c("h3", { staticClass: "mt-0" }, [
                    _vm._v(" Slide heading: " + _vm._s(_vm.slide.heading)),
                  ]),
                  _vm._l(_vm.slide, function (value, key) {
                    return _c("div", { key: key }, [
                      !["heading", "_json_api", "stage"].includes(key)
                        ? _c("div", { staticClass: "mt-1" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(key) +
                                ": " +
                                _vm._s(value) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                    ])
                  }),
                ]
              : _c("ct-centered-spinner", [
                  _vm._v("\n        Loading Slide...\n      "),
                ]),
          ],
          2
        ),
        _vm.loaded
          ? _c(
              "div",
              { staticClass: "navigation-buttons" },
              [
                _c(
                  "div",
                  [
                    !_vm.isFirstSlide
                      ? _c(
                          "b-button",
                          {
                            staticClass: "previous-button",
                            attrs: {
                              variant: "primary",
                              "aria-label": "Previous button",
                            },
                            on: { click: _vm.back },
                          },
                          [
                            _c("fa-icon", {
                              staticClass: "ml-1",
                              attrs: { icon: "chevron-left" },
                            }),
                            _vm._v("\n          Previous\n        "),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "next-button",
                    attrs: {
                      variant: "primary",
                      "aria-label": _vm.isLastSlide
                        ? "Finish button"
                        : "Continue button",
                    },
                    on: { click: _vm.next },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.isLastSlide ? "Finish" : "Continue") +
                        "\n      "
                    ),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }