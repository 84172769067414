<template>
  <div id="stage-viewer">
    <div class="stage">
      <heading v-if="showBackButton" :company="currentCompany" />
      <h3 v-if="stage.title" class="font-weight-bold mt-5"> {{ stage.title }} </h3>
      <progress-bar v-if="steps?.length > 1" :steps="steps" :step-progress="slide.progress" class="mt-4" />

      <div ref="slide" class="slide">
        <template v-if="loaded">
          <h3 class="mt-0"> Slide heading: {{ slide.heading }}</h3>
          <div v-for="(value, key) in slide" :key="key">
            <div v-if="!['heading', '_json_api', 'stage'].includes(key)" class="mt-1">
              {{ key }}: {{ value }}
            </div>
          </div>
        </template>
        <ct-centered-spinner v-else>
          Loading Slide...
        </ct-centered-spinner>
      </div>

      <div v-if="loaded" class="navigation-buttons">
        <div>
          <b-button
            v-if="!isFirstSlide"
            class="previous-button"
            variant="primary"
            aria-label="Previous button"
            @click="back"
          >
            <fa-icon icon="chevron-left" class="ml-1" />
            Previous
          </b-button>
        </div>

        <b-button
          class="next-button"
          variant="primary"
          :aria-label="isLastSlide ? 'Finish button' : 'Continue button'"
          @click="next"
        >
          {{ isLastSlide ? 'Finish' : 'Continue' }}
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'StageViewer',
  components: {
    CtCenteredSpinner:   () => import('@/components/shared/CtCenteredSpinner.vue'),
    ProgressBar:         () => import('@/components/StagelineV3/ProgressBar.vue'),
    Heading:             () => import('@/components/StagelineV3/Heading.vue'),
  },
  data() {
    return {
      loaded: false,
      showBackButton: false,
      companyId: null,
    }
  },
  computed: {
    ...mapGetters('companies', [
      'currentCompany',
    ]),
    ...mapGetters('stagelineV3', [
      'stage',
      'stages',
      'accountsStage',
      'steps',
      'slide',
      'isFirstSlide',
      'isLastSlide',
    ]),
  },
  async mounted() {
    this.companyId = this.$route.params.companyId
    if (this.companyId && this.companyId !== this.currentCompany?.id) {
      await this.setCurrentCompany({ id: this.companyId })
    }

    this.showBackButton = true

    await this.getStage({ id: this.$route.params.stageId, company_id: this.companyId })
    await this.getSteps({})
    await this.updateAndLoadStageForAction('view')
  },
  methods: {
    ...mapActions('companies', [
      'setCurrentCompany',
    ]),
    ...mapActions('stagelineV3', [
      'createTransition',
      'getStage',
      'getStages',
      'getSteps',
      'getAccountsStage',
      'getSlide',
    ]),
    async transitionSlide(direction, entering = true) {
      const distance = direction === 'rtl' ? '25px' : '-25px'
      const slide = this.$refs['slide']
      if (slide) {
        slide.classList.add('slide-transition')
        slide.style.opacity = entering ? 0 : 1
        slide.style.left = entering ? distance : '0px'
        await this.$nextTick()
        slide.style.opacity = entering ? 1 : 0
        slide.style.left = entering ? '0px' : distance
        await new Promise(resolve => setTimeout(resolve, 300)) // wait for 300ms
        slide.classList.remove('slide-transition')
      }
    },
    async transitionEnter(direction) {
      await this.transitionSlide(direction)
    },
    async transitionLeave(direction) {
      await this.transitionSlide(direction, false)
    },
    async next() {
      await Promise.all([
        this.transitionLeave('rtl'),
        this.updateAndLoadStageForAction('next'),
      ])
      await this.transitionEnter('ltr')
    },
    async back() {
      await Promise.all([
        this.transitionLeave('ltr'),
        this.updateAndLoadStageForAction('back'),
      ])
      await this.transitionEnter('rtl')
    },
    async updateAndLoadStageForAction(action_type) {
      this.loaded = false
      await this.createTransition({
        data: { action_type, stage_id: this.$route.params.stageId },
        company_id: this.companyId,
      })

      // Need to re-fetch the stage if no accounts stage is present from the previous request.
      if (!this.stage.accounts_stage) await this.getStage({ id: this.$route.params.stageId, company_id: this.companyId })
      await this.getAccountsStage({})

      if (this.isLastSlide && action_type === 'next') {
        await this.handleStageCompleted()
      }

      await this.getSlide({})
      this.loaded = true
    },
    async handleStageCompleted() {
      // Auto complete the stage and load the next one for the user
      await this.createTransition({
        data: { action_type: 'complete', stage_id: this.$route.params.stageId },
        company_id: this.companyId,
      })

      // Re-fetch prioritized list of stages, but just one.
      await this.getStages({
        limit: 1,
        company_id: this.companyId,
        fields: {
          stage: ['id'],
        },
      })

      const previousStage = this.stage
      await this.getStage({ id: this.stages[0]?.id , company_id: this.companyId })

      this.stage && previousStage.id !== this.stage.id ?
        await this.loadAndGoToNextStage() :
        await this.goToOverview()
    },
    async goToOverview() {
      await this.$router.push({ name: 'overview', params: {
          companyId: this.companyId,
        },
      })
    },
    async loadAndGoToNextStage() {
      await this.getSteps({})
      await this.$router.push({ name: 'stage-viewer', params: {
          companyId: this.companyId,
          stageId: this.stage.id,
        },
      })

      // Mounted should run again, but doing this anyways.
      await this.updateAndLoadStageForAction('view')
    },
  },
}
</script>

<style scoped lang="scss">
#stage-viewer {
  margin: 5em 15%;
  overflow: hidden;
  width: 100%;
  justify-items: center;

  .stage {
    width: 100%;
    max-width: 65em;
  }

  .navigation-buttons {
    margin-top: 1.5em;
    margin-bottom: 0.25em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1em;

    .next-button, .previous-button {
      border-radius: $stageline-primary-border-radius !important;
    }

    .next-button {
      background-color: #2E3798;
      border: none;

      &:hover {
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        transform: translateY(-1px);
      }
    }

    .previous-button {
      background-color: white;
      border: 0.0625em solid #CECED2;
      color: #CECED2;

      &:hover {
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        transform: translateY(-1px);
      }
    }
  }

  .slide {
    margin-top: 3em;
    border: 1px solid lightgray;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 0.5em;
    padding: 2em;
    position: relative;
  }

  .slide-transition {
    transition: opacity 0.3s, left 0.3s;
  }
}
</style>
